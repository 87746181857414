import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authThunks } from '../../actions-thunks/auth-thunks';

const LogoutPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authThunks.signoutTC())
  }, [dispatch])
}

export default LogoutPage;