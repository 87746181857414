//auth
export const SET_AUTHENTICATION = "AUTH_REDUCER/SET_AUTHENTICATION";
export const SET_SIGN_UP_DATA = "AUTH_REDUCER/SET_SIGN_UP_DATA";
export const ACTIVATION_REQUEST_STATUS =
  "AUTH_REDUCER/ACTIVATION_REQUEST_STATUS";
export const MAIL_SEND_STATUS = "AUTH_REDUCER/MAIL_SEND_STATUS";
export const CHANGE_PASSWORD_TOKEN = "AUTH_REDUCER/CHANGE_PASSWORD_TOKEN";

//profile
export const UPDATE_PROFILE = "PROFILE_REDUCER/UPDATE_PROFILE";
export const UPDATE_PROFILE_WITH_SUMSUB_DATA = "PROFILE_REDUCER/UPDATE_PROFILE_WITH_SUMSUB_DATA";
export const SET_DATA_REQUESTED = "PROFILE_REDUCER/SET_DATA_REQUESTED";
export const UPDATE_FILES = "PROFILE_REDUCER/UPDATE_FILES";
export const DELETE_FILE = "PROFILE_REDUCER/DELETE_FILE";
export const UPDATE_CERTAIN = "PROFILE_REDUCER/UPDATE_CERTAIN";
export const UPDATE_RATES = "PROFILE_REDUCER/UPDATE_RATES";
export const CREATE_ACCOUNT = "PROFILE_REDUCER/CREATE_ACCOUNT";
export const IS_DEMO_ACCOUNT = "PROFILE_REDUCER/IS_DEMO_ACCOUNT";

//supportive
export const SET_LOADING = "UTILITY_REDUCER/SET_LOADING";
export const SET_SHOW_SPINNER = "UTILITY_REDUCER/SET_SHOW_SPINNER";
export const SET_IS_DOWNLOADING_IMAGE = "UTILITY_REDUCER/SET_IS_DOWNLOADING_IMAGE";
export const GET_ERRORS = "UTILITY_REDUCER/GET_ERRORS";
export const SET_TOAST = "UTILITY_REDUCER/SET_TOAST";
export const TRIGGER_REDIRECT = "UTILITY_REDUCER/TRIGGER_REDIRECT";
export const DISABLE_SUBMIT_BUTTON = "UTILITY_REDUCER/DISABLE_SUBMIT_BUTTON";

//sumsub 
export const SET_ACCESS_TOKEN = "SUMSUB_REDUCER/SET_ACCESS_TOKEN";

//routes
export const TO_SIGN_IN = `/sign-in`;
export const TO_SIGN_UP = `/sign-up`;
export const TO_ACTIVATION_PAGE = `/activate`;
export const TO_SIGN_UP_CONFIRMATION = `/sign-up-confirmation`;
export const TO_ACTIVATE_CONFIRMATION = `/activate-confirmation`;
export const TO_RESET_PASSWORD = `/reset-password`;
export const TO_HOME_PAGE = `/home`;
export const TO_OTP = `/verify`;
export const OTP_ERROR = `/auth/otp`;
export const VERIFY_ERROR = `/auth/verify`;
export const TO_RECOVER_PASSWORD = `/recover-password`;
export const LOGOUT = `/log-out`;

//inner routes
export const ABOUT_ZIMTRA = "https://zimtra.com/";
export const TO_SIGN_OUT = "/sign-out";

//  ************* error types ************

//auth error types
export const SIGN_IN_ERROR = "auth/signin";
export const CHECK_TOKEN_ERROR = "auth/check-token";
export const SIGN_UP_ERROR = "auth/signup";
export const PASSWORD_RECOVERY_ERROR = "auth/password-recover";
export const ACTIVATE_ERROR = "auth/activate";
export const NEW_PASSWORD__ERROR = "auth/new-password";

//sumsub error types
export const SET_ACCESS_TOKEN_ERROR = "sumsub/SET_ACCESS_TOKEN_ERROR";
export const SYNC_WITH_BACK_ERROR = "sumsub/SYNC_WITH_BACK_ERROR";


//account error types
export const CREATE_ACCOUNT_ERROR = "profile/createaccount";

// tokens types
export const SIGN_UP_TOKEN = "onboarding2/signuptoken"; 
export const LOG_IN_TOKEN = "onboardingToken";
export const PORTAL_TOKEN = "traderPortalToken";
export const EMAIL_TOKEN = "onboarding2/email";
export const FIRST_LAST_NAME = "onboarding2/first-last";
export const REDIRECT_URL = "onboarding-to-portal-url";

//default statuses
export const SUCCESS = "success_status";
export const ERROR = "success_error";

//demo statuses
export const REGISTRATION_DEMO_STATUSES = {
  AVAILABLE: 'Available',
  INPROCESS: 'InProcess',
  UNAVAILABLE: 'Unavailable'
  };


