import React from "react";

import "./stepper.scss";

const Stepper = ({ steps, currentStep, isSumsubForm }) => {
  const renderSteps = steps.map((step, index) => {
    const returnedValue = (
      <Step
        key={index + "-" + step.name}
        step={step}
        currentStep={currentStep}
        stepIdx={index}
        stepsLen={steps.length}
        isSumsubForm={isSumsubForm}
      />
    );
    if (isSumsubForm && index !== 3) return returnedValue;
    if (!isSumsubForm && index !== 4) return returnedValue;
    return null;
  });
  return <div className="stepper-wrapper">{renderSteps}</div>;
};

export default Stepper;

const Step = ({ step, currentStep, stepIdx, stepsLen }) => {
  const setBackgroundColor =
    step.step <= currentStep.step
      ? "done-step-background"
      : "not-done-step-background";
  const setBackgroundColorLeft =
    step.step < currentStep.step
      ? "done-step-background"
      : "not-done-step-background";
  const setFontColor =
    step.step > currentStep.step ? "not-done-step-color" : "done-step-color";
  return (
    <div className="step-wrapper">
      <div className={`step-name ${setFontColor}`}>{step.name}</div>
      <div className="step-line-wrapper">
        {step.step < currentStep.step ? (
          <div className="checkmark-icon"></div>
        ) : (
          <div className="step-circle-container">
            <div className={`step-circle ${setBackgroundColor}`} />
          </div>
        )}
        {stepIdx !== stepsLen - 2 && stepsLen > 1 && (
          <div className={`left-short-line ${setBackgroundColorLeft}`} />
        )}
        {stepIdx !== 0 && (
          <div className={`right-short-line ${setBackgroundColor}`} />
        )}
      </div>
    </div>
  );
};
