import { combineReducers } from "redux";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import utilityReducer from "./utilityReducer";
import sumsubReducer from "./sumsubReducer";

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  utility: utilityReducer,
  sumsub: sumsubReducer,
});
