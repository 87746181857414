import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../utilities/element/Button";
import Input from "../../utilities/element/Input";
import { setSignUpDataAC } from "../../../actions-thunks/actions";
import { authThunks } from "../../../actions-thunks/auth-thunks";
import { utilityFns } from "../../utilities/utility-functions";
import { TO_HOME_PAGE } from "../../../reducers/types";

import "./sign-up-page.scss";

const SignUpPage = () => {
  const [localState, setLocalState] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const { authenticated } = useSelector((state) => state.auth);
  const [activateButton, setActiveateButton] = useState(false);
  const { firstName, lastName, email } = localState;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const messageForInvalidFields =
    "Use only Latin letters, dots, apostrophes and space between words";
  const messageForInvalidEmail =
    "Please use proper email address";

  useEffect(() => {
    if (authenticated) {
      navigate(TO_HOME_PAGE);
    } else {
      const queryParams = parseQuery();
      const campaign = sessionStorage.getItem('Campaign');
      const adset = sessionStorage.getItem('Adset');
      const ad = sessionStorage.getItem('Ad');
      const query = sessionStorage.getItem('onboardingParams');
      if (queryParams.Campaign && !campaign) { sessionStorage.setItem('Campaign', queryParams.Campaign); }
      if (queryParams.Adset && !adset) { sessionStorage.setItem('Adset', queryParams.Adset); }
      if (queryParams.Ad && !ad) { sessionStorage.setItem('Ad', queryParams.Ad); }
      if ((queryParams.Campaign || queryParams.Adset || queryParams.Ad) && !query) { sessionStorage.setItem('onboardingParams', window.location.search.substring(1)); }
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      utilityFns.validEnglishTextAndNumber(firstName) &&
      utilityFns.validEnglishTextAndNumber(lastName) &&
      utilityFns.validEmail(email)
    ) {
      setActiveateButton(true);
    } else {
      setActiveateButton(false);
    }
  }, [firstName, lastName, email]);

  function parseQuery() {
    const queryString = window.location.search.substring(1);
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1] || '');

      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
      query[capitalizedKey] = value;
    }
    return query;
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ ...localState, [name]: value });
  };

  const getSessionStorageItem = (key) => {
    return sessionStorage.getItem(key);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let dataToSend = { ...localState };
    setActiveateButton(false);
    if (
      !!getSessionStorageItem("Campaign") ||
      !!getSessionStorageItem("Adset") ||
      !!getSessionStorageItem("Ad") ||
      !!getSessionStorageItem("onboardingParams")
    ) {
      const marketingData = {
        Campaign: getSessionStorageItem("Campaign"),
        Adset: getSessionStorageItem("Adset"),
        Ad: getSessionStorageItem("Ad"),
        onboardingParams: getSessionStorageItem("onboardingParams")
      }
      dataToSend = {
        ...dataToSend,
        marketingData
      };
    }
    const accountType = localStorage.getItem("accountType") || null;
    if (accountType) dataToSend.accountType = accountType;
    dispatch(setSignUpDataAC(dataToSend));
    dispatch(authThunks.signupTC(dataToSend));
  };

  return (
    <div className="form-block-signup">
      <Form className="form-wrapper">
        <h4>Create Your Profile</h4>
        <Form.Group className="form-group-block mb-3">
          <Form.Label className="form-label center-label ">
            First Name
          </Form.Label>
          <Input
            type="text"
            value={firstName}
            messagefornotvalid={messageForInvalidFields}
            onChange={handleChange}
            name="firstName"
            placeholder="Required"
            maxLength="40"
            onValidate={utilityFns.validEnglishTextAndNumber}
          />
        </Form.Group>
        <Form.Group className="form-group-block mb-3">
          <Form.Label className="form-label center-label ">
            Last Name
          </Form.Label>
          <Input
            type="text"
            value={lastName}
            messagefornotvalid={messageForInvalidFields}
            onChange={handleChange}
            name="lastName"
            placeholder="Required"
            maxLength="40"
            onValidate={utilityFns.validEnglishTextAndNumber}
          />
        </Form.Group>
        <Form.Group className="form-group-block mb-3">
          <Form.Label className="form-label center-label ">Email</Form.Label>
          <Input
            type="email"
            value={email}
            whitebg
            messagefornotvalid={email && messageForInvalidEmail}
            onChange={handleChange}
            name="email"
            placeholder="Required"
            maxLength="45"
            onValidate={utilityFns.validEmail}
          />
        </Form.Group>
        <div className="button-section">
          <Button onClick={submitHandler} disabled={!activateButton}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SignUpPage;
