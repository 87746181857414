import React, {useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import AllRoutes from './routes/routes';
import MainTaskbar from './ui/taskbar/main-taskbar';
import MainFooter from './ui/footer/MainFooter';
import store from './store';
import { utilityFns } from "./ui/utilities/utility-functions"
import './App.scss';

const css = `
  input[type="date"].form-control::before {
    background-image: url(${utilityFns.getImageFromStore('calendar.png', '09', '2023')});
    background-size: 25px;
  }

  .stepper-wrapper .step-wrapper .checkmark-icon:after {
    background-image: url(${utilityFns.getImageFromStore('checkmark.png', '09', '2023')});
    background-size: 24px;
  }

  .main-checkbox > input:after {
    background-image: url(${utilityFns.getImageFromStore('unchecked.png', '09', '2023')});
    background-size: 24px;
  }

  .main-checkbox > input:checked:after {
    background-image: url(${utilityFns.getImageFromStore('checked.png', '09', '2023')});
    background-size: 24px;
  }

  .dropdown-wrapper select {
    background-image: url(${utilityFns.getImageFromStore('arrow-down.png', '09', '2023')});
  }
`

const App = () => {

  useEffect(() => {
    const woriginProd = window.location.origin === "https://zimtra.com";

    if (
      woriginProd
      && !document.querySelector('[http-equiv="Content-Security-Policy"]')
    ) {
      // Set the CSP meta tag
      const cspTag = document.createElement('meta');
      cspTag.setAttribute('http-equiv', 'Content-Security-Policy');
      cspTag.setAttribute('content', 'upgrade-insecure-requests');
      document.head.appendChild(cspTag);
    }

    // Cleanup when the component unmounts
    return () => {
      if (woriginProd) {
        // Remove the CSP meta tag when the component unmounts
        const cspTag = document.querySelector('[http-equiv="Content-Security-Policy"]');
        if (cspTag) {
          document.head.removeChild(cspTag);
        }
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
      <style>{css}</style>
        <WrappedApp />
      </Provider>
    </BrowserRouter>
  );
}

const WrappedApp = () => {
  const { showSpinner, disableSubmitButton } = useSelector((state) => state.utility);

  return (
    <div className="App-onbording">
      <MainTaskbar />
      {showSpinner && <div className="spinner-wrapper">
        <Spinner className="spinner" animation="grow" />
        <div className="spinner-background" />
      </div>}
      {disableSubmitButton && <div className="submit-spinner-wrapper">
        <Spinner className="spinner" animation="grow" />
        <div className="spinner-background" />
      </div>}
      <AllRoutes />
      <MainFooter />
    </div>
  )
}

export default App;
