import React, { useEffect } from "react";
import { useState } from "react";

import "./account-setup.scss";
import { utilityFns } from "../../utilities/utility-functions";

const AccountSetupStep = () => {
  const [count, setCount] = useState(30);

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
    if (count === 0) {
      renderToPortalHref();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const renderToPortalHref = () => {
    utilityFns.redirectToPortal();
  };

  const buttonElement = (title, isDisabled = false) => (
    <div
      className={`button-element ${isDisabled ? "disabled" : undefined}`}
      onClick={renderToPortalHref}
    >
      {title}
    </div>
  );

  const countTitle = `Go To Portal ${count > 0 ? `(${count})` : undefined}`;

  return (
    <div className="account-setup-wrapper">
      <h4 id="accountsetup" className="account-setup-title">
        Application Complete
      </h4>
      <div className="account-setup-text">
        <div>Thank you for completing your trading application!</div>
        <div>You will now be redirected to your Trader Portal.</div>
      </div>
      {count > 0
        ? buttonElement(countTitle)
        : buttonElement("Redirecting...", true)}
    </div>
  );
};

export default AccountSetupStep;
