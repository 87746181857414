import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { authThunks } from "../actions-thunks/auth-thunks";

import "../App.scss";

const ProtectedRoute = () => {
  const { authenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authenticated) {
      dispatch(authThunks.checkTokenTC())
    }
  }, [authenticated, dispatch])

  return authenticated && <Outlet />
};

export default ProtectedRoute;
