import React from "react";
import { utilityFns } from "./utility-functions";

const styles = {
  maxHeight: "20px",
  maxWeight: "20px",
};

const EyeIcon = ({ show }) => {
  return (
    <img
      style={styles}
      src={utilityFns.getImageFromStore(`${show ? "show" : "hide"}-eye.png`, "06")}
      alt="eye-showed"
    />
  );
};

export default EyeIcon;
