import SumsubWebSdk from "@sumsub/websdk-react";

import { useEffect } from "react";
import { sumsubThunks } from "./actions-thunks/sumsub-thunk";
import { useDispatch, useSelector } from "react-redux";
import { SumSubAPI } from "./api/sumsub-api";

const SumSubInstance = ({ setLocalState }) => {
  const { accessToken } = useSelector((state) => state.sumsub);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accessToken) {
      dispatch(sumsubThunks.getAccessTokenTC());
    }
  }, [accessToken, dispatch]);

  const getNewAccessToken = async () => {
    try {
      const response = await SumSubAPI.getAccessToken();
      if (response?.data?.success) {
        console.log("Token request succeed");
        return response.data.token;
      } else {
        console.error("Token request did not succeed");
      }
    } catch (error) {
      console.error("Error fetching new access token:", error.message);
    }
  };

  const onMessage = (messageName, value) => {
    if (messageName === "idCheck.onStepInitiated") {
      window.scrollTo(0, 0);
    }
    if (
      messageName === "idCheck.onApplicantStatusChanged" &&
      value.reviewResult?.reviewAnswer === "GREEN" &&
      value.reviewStatus === "completed"
    ) {
      setLocalState((localState) => ({
        ...localState,
        sumsubDocsAreVerified: true,
      }));
    }
  };

  if (accessToken)
    return (
      <SumsubWebSdk
        onMessage={onMessage}
        accessToken={accessToken}
        expirationHandler={getNewAccessToken}
      />
    );
};

export default SumSubInstance;
