import axios from "axios";
import { LOG_IN_TOKEN } from "../reducers/types";
import Cookies from "js-cookie";
import getApiUrl from "./helpers";

export const AuthAPI = {
  signup: async (userData) => {
    const response = await instance.post("/registration/v2/sign-up", userData);
    return response;
  },
  signin: async (userData) => {
    const response = await instance.post("/registration/v2/sign-in", userData);
    return response;
  },
  logout: async () => {
    const response = await instance.get("/logout", {
      headers: setHeader(LOG_IN_TOKEN),
    });
    return response;
  },
  recoverPassword: async (userData) => {
    const response = await instance.post("/registration/v2/forgot-password", userData);
    return response;
  },
  changePassword: async (userData, token) => {
    const response = await instance.put("/users/newpassword", userData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
  checkTokenValidity: async (token) => {
    try {
      // Split the token into its parts
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('Invalid token structure');
      }
  
      // Decode the payload
      const payloadBase64 = parts[1];
      const payloadJson = AuthAPI.base64UrlDecode(payloadBase64);
      const decodedPayload = JSON.parse(payloadJson);
  
      // Get the expiration time from the token (in seconds since epoch)
      const exp = decodedPayload.exp;
      if (!exp) {
        throw new Error('Token does not contain exp claim');
      }
  
      // Calculate the time remaining before expiration
      const now = Math.floor(Date.now() / 1000); // Current time in seconds since epoch
      const expiresIn = exp - now;
  
      if (expiresIn > 0) {
        // Token is valid
        return {
          status: 200,
          data: {
            success: true,
            expires: expiresIn, // Time remaining in seconds
          },
        };
      } else {
        // Token has expired
        return {
          status: 401,
          data: {
            success: true,
            expires: null,
          },
        };
      }
    } catch (error) {
      // If any error occurs (e.g., token is invalid), treat the token as expired
      return {
        status: 401,
        data: {
          success: true,
          expires: null,
        },
      };
    }
  },
  
  base64UrlDecode: (str) => {
    // Replace URL-safe characters with base64 standard characters
    str = str.replace(/-/g, '+').replace(/_/g, '/');
  
    // Pad the string with '=' characters to make its length a multiple of 4
    while (str.length % 4) {
      str += '=';
    }
  
    // Decode the base64 string
    if (typeof window !== 'undefined' && window.atob) {
      // Browser environment
      return window.atob(str);
    } else {
      // Node.js environment
      return Buffer.from(str, 'base64').toString('utf8');
    }
  },
  activate: async (token, userData) => {
    const response = await instance.post(`/registration/v2/activate`, userData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  },
  getToken: () => {
    if (Cookies.get(LOG_IN_TOKEN)) {
      return Cookies.get(LOG_IN_TOKEN)
    }
    if (window.location.hostname === "localhost") {
      return localStorage.getItem(LOG_IN_TOKEN)
    }
    return null
  },
  getSessionToken: () => sessionStorage.getItem(LOG_IN_TOKEN)
};

export const setHeader = (type) => {
  switch (type) {
    case LOG_IN_TOKEN:
      return { Authorization: `Bearer ${AuthAPI.getToken()}` };
    default:
      break;
  }
};

export const instance = axios.create({
  baseURL: getApiUrl(),
});
