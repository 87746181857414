import Cookies from "js-cookie";
import { accountio } from "../api/marketpayout-api.js";
import { profileAPI } from "../api/profile-api.js";
import {
  CREATE_ACCOUNT_ERROR,
  FIRST_LAST_NAME,
  LOG_IN_TOKEN,
  PORTAL_TOKEN,
  REDIRECT_URL,
  SIGN_IN_ERROR,
} from "../reducers/types.js";
import { utilityFns } from "../ui/utilities/utility-functions.js";
import {
  setDataRequestedAC,
  setLoadingAC,
  seIsDemoAccountAC,
  updateCertainItemsAC,
  updateFilesAC,
  updateProfileAC,
  setShowSpinnerAC,
  updateRatesAC,
  setIsDownloadingImageAC,
  deleteFileAC,
  setErrorAC,
  createAccountAC,
  setDisableSubmitButtonAC,
} from "./actions.js";
import { authThunks } from "./auth-thunks.js";

export const profileThunks = {
  getAllMarketData: () => async (dispatch) => {
    try {
      dispatch(setShowSpinnerAC(true));
      dispatch(setLoadingAC(true));
      await Promise.allSettled(
        Object.values(accountio).map(async (fn) => {
          return await new Promise(async (res, rej) => {
            if (typeof fn === "function") {
              const response = await fn.call();
              const data = response[1];
              if (data.status === 200) {
                switch (response[0]) {
                  case "calculationrates":
                    dispatch(updateRatesAC({ [response[0]]: data.data.data }));
                    res("calculationrates");
                    return;
                  case "countryrates":
                    dispatch(updateRatesAC({ [response[0]]: data.data || [] }));
                    res("countryrates");
                    return;
                  case "country":
                    const allowTradeCountries = data.data.countries
                      .filter((c) => c.allowtrade)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((r) => {
                        return { value: r.code, text: r.name };
                      });
                    dispatch(updateRatesAC({ countries: allowTradeCountries }));
                    res("country");
                    return;
                  case "marketdataproviders":
                    const rates = {};
                    const marketdataproviders =
                      data.data?.marketdataproviders || [];
                    const bundle = data.data?.bundle || [];

                    rates.marketdataproviders = marketdataproviders;

                    rates.bundles = bundle
                      .sort(utilityFns.sortByOrderField)
                      .map((b) => {
                        return {
                          name: b.name,
                          description: b.description,
                          price: 0,
                        };
                      });

                    const groups = marketdataproviders.reduce((a, c) => {
                      if (!a.includes(c.group)) {
                        a.push(c.group);
                      }
                      return a;
                    }, []);
                    rates.groups = groups;
                    dispatch(updateRatesAC({ ...rates }));
                    res("marketdataproviders");
                    return;
                  case "tradingplatforms":
                    dispatch(
                      updateRatesAC({
                        [response[0]]: data.data.tradingplatforms || [],
                      })
                    );
                    res("tradingplatforms");
                    return;
                  case "documenttemplate":
                    dispatch(
                      updateCertainItemsAC({
                        agreementTemplate: data.data.data[0],
                      })
                    );
                    res("documenttemplate");
                    return;
                  case "accounttypes":
                    dispatch(
                      updateRatesAC({ [response[0]]: data.data[response[0]] })
                    );
                    res("accounttypes");
                    return;
                  default:
                    dispatch(updateRatesAC({ [response[0]]: data.data }));
                    res("default");
                }
              }
              return null;
            }
            return null;
          });
        })
      );
      // console.log("promiseAwaited", promiseAwaited)
    } catch (error) {
      console.trace(error);
    } finally {
      dispatch(setShowSpinnerAC(false));
      dispatch(setLoadingAC(false));
    }
  },
  getProfileData: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.getProfile();
      if (response.status === 200) {
        const foundProfile = response.data.items.find((i, ind, arr) => {
          if (arr.length > 1) {
            return i.softwareType !== "Sim"
          } else {
            return ind === 0
          }
        })
        let newProfile = { ...foundProfile };
        const { firstName, lastName } = newProfile;
        localStorage.setItem(FIRST_LAST_NAME, `${firstName} ${lastName}`);
        // convert markets in array-of-strings view
        if (
          newProfile.markets &&
          !!newProfile.markets.length &&
          typeof newProfile.markets[0] === "object"
        ) {
          newProfile.markets = newProfile.markets.map(
            (m) => `${m.group}-${m.market}`
          );
        }
        dispatch(updateProfileAC(newProfile));
        // dispatch(updateFilesAC(response.data.scans));
        dispatch(setDataRequestedAC(true));
      }
    } catch (error) {
      dispatch(setLoadingAC(false));
      console.log(error)
      if (error.response.status === 401) {
        dispatch(authThunks.signoutTC());
        dispatch(
          setErrorAC({
            type: SIGN_IN_ERROR,
            message: "Your token has expired. Please sign in again to proceed",
          })
        );
      }
    }
  },

  updateProfileData: (data, isBusinessDeal) => async (dispatch) => {
    const formatDataForBD = () => {
      const { _id, software, softwareType, accountType, marketdata, agreement } = data;

      return {
        clientID: _id,
        software: software,
        softwareType: softwareType,
        accountType: accountType,
        marketdata: marketdata,
        agreement: {
          rules: agreement.rules,
          marketData: agreement.marketData,
        }
      };
    }
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.updateProfile(isBusinessDeal ? formatDataForBD(): data);
      if (response.status === 200) {
        const response2 = await profileAPI.updateProfileStatus({newstatus: 'Review & agreement', clientID: data._id});
        if (response2.status === 200) {
          dispatch(updateProfileAC({...response.data.application}));
          dispatch(setDataRequestedAC(true));
        }
      }
    } catch (error) {
      dispatch(setLoadingAC(false));
      if (error.response.status === 401) {
        dispatch(authThunks.signoutTC());
        dispatch(
          setErrorAC({
            type: SIGN_IN_ERROR,
            message: "Your token has expired. Please sign in again to proceed",
          })
        );
      }
    }
  },

  getScanData: (scanData) => async (dispatch) => {
    const getSingleScan = async (scanName, isSingle = false) => {
      const response = await profileAPI.getScan(scanName);
      if (response.status === 200) {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);

        reader.onload = () => {
          const image = reader.result;

          const makeImage = {
            kind: scanName,
            image,
          };
          isSingle && dispatch(setIsDownloadingImageAC([]));
          dispatch(updateFilesAC({ [scanName]: makeImage }));
          dispatch(setDataRequestedAC(true));
        };
        return true;
      }
    };

    try {
      if (Object.values(scanData).length > 1) {
        const setFetchData = Object.values(scanData)
          .map((i) => (typeof i === "string" ? i : null))
          .filter((n) => n);
        dispatch(
          setIsDownloadingImageAC(
            setFetchData.map((i) => ({ status: true, name: i }))
          )
        );
        const result = await Promise.all(
          Object.values(scanData).map(async (scan) => {
            if (typeof scan === "string") {
              await getSingleScan(scan);
            }
          })
        );
        if (result) {
          dispatch(setIsDownloadingImageAC([]));
        }
      } else {
        getSingleScan(scanData[0], true);
      }
    } catch (error) {
      console.trace(error);
    }
  },
  deleteScan: (imageData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.removeRegistrationScan(imageData);
      if (response.status === 200 && response.data.success) {
        dispatch(deleteFileAC(imageData.kind.kind));
        dispatch(setLoadingAC(false));
      }
    } catch (error) {
      dispatch(setLoadingAC(false));
      console.trace(error);
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  submitProfileData: (profile, agreementTemplate) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      dispatch(setDisableSubmitButtonAC(true));
      const {personal, address, _id} = profile;

      const submitData = {
        agreementdocument: {
          name: agreementTemplate.name,
          version: agreementTemplate.version,
        },
        applicationID: _id,
      };
      const formattedUpdateData = {
        clientID: _id,
        personal: {
          citizenship: personal.citizenship,
          firstName: personal.firstName,
          lastName: personal.lastName,
          middleName: personal.middleName,
          title: personal.title,
        },
        address: {
          street: address.street,
          street2: address.street2,
          city: address.city,
          state: address.state,
          country: address.country,
          postalCode: address.postalCode,
        }
      }
      await profileAPI.updateProfile(formattedUpdateData);

      const response = await profileAPI.submitProfile(submitData);
      if (response.status === 200) {
        dispatch(setDisableSubmitButtonAC(false));
        dispatch(createAccountAC(true));
        if (window.fbq === "function") {
          window.fbq("track", "SubmitApplication");
        }
        localStorage.getItem("onboarding2-rememberme") === "yes"
          ? localStorage.setItem(REDIRECT_URL, response.data.url)
          : sessionStorage.setItem(REDIRECT_URL, response.data.url);
        const domain = utilityFns.getDomainFromHostname(window?.location.hostname);
        const token = Cookies.get(LOG_IN_TOKEN);
        Cookies.remove(LOG_IN_TOKEN, {
          domain,
          sameSite: "None",
          secure: true,
        });
        Cookies.set(PORTAL_TOKEN, token, {
          expires: 30,
          domain,
          sameSite: "None",
          secure: true,
        });
      }
    } catch (error) {
      console.trace(error);
      dispatch(setDisableSubmitButtonAC(false));
      dispatch(createAccountAC(false));
      dispatch(
        setErrorAC({
          type: CREATE_ACCOUNT_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  deleteProfile: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = profileAPI.deleteProfile();
      if (response.status === 200) {
      }
    } catch (error) {
      console.trace(error);
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  demoProfileStart: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.demoProfileStart();
      if (response.status === 200) {
        dispatch(seIsDemoAccountAC(true));
      }
    } catch (error) {
      console.trace(error);
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  demoProfileStop: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.demoProfileStop();
      if (response.status === 200) {
        dispatch(seIsDemoAccountAC(false));
      }
    } catch (error) {
      console.trace(error);
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
