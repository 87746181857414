import React from "react";
import "./style.scss";

class RangeValue extends React.Component {
    constructor(props) {
      super(props); 
      this.state = { focused: false, value: 0 };
      this.localChange =this.localChange.bind(this);
    }

    localChange(e) {
      const value = e.target.value;
      this.setState({value});
      this.props.onChange(e);
    }

    render() {
      const mixmaxformatter = this.props.minmaxformatter ? this.props.minmaxformatter : x => x;
      const minError = (this.props.value<this.props.min) ? this.props.overnight?.name === "overnight" && !this.props.overnight?.status ? "" : 'errorlimit' : '';
      const maxError = (this.props.value>this.props.max) ? this.props.overnight?.name === "overnight" && !this.props.overnight?.status ? "" : 'errorlimit' : '';

      const propsForInput = {...this.props};
      delete propsForInput.minmaxformatter;
      return (
        <div className={`rangevalue ${this.props.overnight?.name === "overnight" && !this.props.overnight?.status && "opacite-element"}`}>
          <div className="placeholder-wrapper" >
            <span className="placeholder">{this.props.placeholder}</span>
            <input 
              className={`placeholdervalue ${minError} ${maxError}`} 
              type="text"
              disabled={this.props.disabled}
              value={(this.state.focused) ? this.state.value : '✎ '+ this.props.placeholdervalue} 
              onBlur={(e)=>{ this.setState({focused: false});
                this.props.onChange(e, {min: this.props.min, max: this.props.max});
              }}
              onFocus={(e)=>{ this.setState({focused: true, value: this.props.value});}} 
              onChange={this.localChange}
            />
          </div>
          <div className="rangeslider">
            <span className={`min ${minError}`}>{mixmaxformatter(this.props.min)}</span>
            <input type="range" {...propsForInput} onChange={(e) => { e.target.value = 100*(Math.round(parseInt(e.target.value)/100));this.props.onChange(e)}}/>
            <span className={`max ${maxError}`}>{mixmaxformatter(this.props.max)}</span>
          </div>
      </div>
      );
    }
}

export default RangeValue;