import React from "react";
import Form from "react-bootstrap/Form";
import { utilityFns } from "../utility-functions";

import "./style.scss";

const DateInput = (props) => {
    const mandatory =
      typeof props.required !== "undefined" && !props.value;
    return (
      <div className="input-wrapper onb-date">
        <span className="label">{props.label}</span>
        <Form.Control
          className={`${props.formnotvalid ? "not-valid-form" : undefined} ${utilityFns.strToBoolean(props.isgreyed) ? "greyed-field" : undefined}`}
          data-date-format="YYYY MM DD"
          type="date"
          {...props}
          mandatory={mandatory.toString()}
        />
        {props.formnotvalid && <div className={"not-valid-form-message"}>{props.messagefornotvalid}</div>}
      </div>
    );
}

export default DateInput;
