import { SET_ACCESS_TOKEN, UPDATE_PROFILE_WITH_SUMSUB_DATA } from "./types";

const initialState = {
  accessToken: null,
  sumsubData: null,
};

const sumsubReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload,
      };
    case UPDATE_PROFILE_WITH_SUMSUB_DATA:
      return {
        ...state,
        sumsubData: payload,
      };
    default:
      return state;
  }
};

export default sumsubReducer;
