import {
  UPDATE_PROFILE,
  UPDATE_FILES,
  UPDATE_CERTAIN,
  UPDATE_RATES,
  SET_DATA_REQUESTED,
  DELETE_FILE,
  CREATE_ACCOUNT,
  IS_DEMO_ACCOUNT,
} from "./types";

const initialState = {
  profile: {
    markets: [],
    pep: false,
    performanceBond: 0,
    initialBuyingPower: 0,
    maximumDailyLoss: 0,
    overnightBuyingPower: 0,
  },
  dataRequested: false,
  files: null,
  rates: {
    accounttypes: [],
    bundles: [],
    calculationrates: [],
    countries: [],
    countryrates: [],
    groups: [],
    marketdataproviders: [],
    tradingplatforms: [],
  },
  signature: {},
  agreementTemplate: {},
  accountIsCreated: false,
  isDemoAccount: false,
};

const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: { ...state.profile, ...payload },
      };
    case SET_DATA_REQUESTED:
      return {
        ...state,
        dataRequested: payload,
      };
    case UPDATE_FILES:
      return {
        ...state,
        files: { ...state.files, ...payload },
      };
    case UPDATE_RATES:
      return {
        ...state,
        rates: { ...state.rates, ...payload },
      };
    case UPDATE_CERTAIN:
      return {
        ...state,
        ...payload,
      };
    case DELETE_FILE:
      const newFIles = { ...state.files };
      delete newFIles[payload];
      return {
        ...state,
        files: newFIles,
      };
    case CREATE_ACCOUNT:
      return {
        ...state,
        accountIsCreated: payload,
      };
    case IS_DEMO_ACCOUNT:
      return {
        ...state,
        isDemoAccount: payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
