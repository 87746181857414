import { setErrorAC } from "../actions-thunks/actions";
import { authThunks } from "../actions-thunks/auth-thunks";
import { SIGN_IN_ERROR } from "../reducers/types";
import { AuthAPI, instance } from "./auth-api";

export const profileAPI = {
  getScan: async (scanName) => {
    const result = await instance.get(`/registration/scan/${scanName}`, {//=> doesn't work
      headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
      responseType: "blob",
    });

    return result;
  },
  uploadRegistrationScan: async (profile, imageData, dispatch) => {//=> doesn't work
    try {
      const formData = new FormData();
      formData.append("filename", imageData.file.name);
      formData.append("kind", imageData.kind);
      formData.append("image", imageData.file);

      const options = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${AuthAPI.getToken()}`,
        },
      };
      const result = await instance.post(`/registration/scan`, formData, options);
      return result;
    } catch (error) {
      if(error.response.status === 401) {
        dispatch(authThunks.signoutTC())
        dispatch(setErrorAC({ type: SIGN_IN_ERROR, message: "Your token has expired. Please sign in again to proceed" }));
      }
    }
  },
  removeRegistrationScan: async (imageData) => {
    const result = await instance.delete(`/registration/scan`, {//=> doesn't work
      headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
      data: {kind: imageData.kind.kind },
    });
    return result;
  },
  getProfile: async (check) => {
    if (check) {
      const token = AuthAPI.getToken();
      if (token) {
        const result = await instance.get(`/application/list`, {
          headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
        });
        return result;
      } else return "null"
    }
    const result = await instance.get(`/application/list`, {
      headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
    });
    return result;
  },
  updateProfile: async (updateData) => {
    const result = await instance.post(`/application`, updateData, {
      headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
    });
    return result;
  },
  updateProfileStatus: async (body) => {
    const result = await instance.put(`/application/status`, body, {
      headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
    });
    return result;
  },
  submitProfile: async (submitData) => {
    const result = await instance.post(
      `/registration/v2/submit`,
      submitData,
      { headers: { Authorization: `Bearer ${AuthAPI.getToken()}` } }
    );
    return result;
  },
  deleteProfile: async () => {
    const result = await instance.delete(`/registration`, {//=> doesn't work
      headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
    });
    return result;
  },
  demoProfileStart: async () => {//=> doesn't work
    const result = await instance.post(
      `/demo/start`,
      {},
      { headers: { Authorization: `Bearer ${AuthAPI.getToken()}` } }
    );
    return result;
  },
  demoProfileStop: async () => {//=> doesn't work
    const result = await instance.post(
      `/demo/stop`,
      {},
      { headers: { Authorization: `Bearer ${AuthAPI.getToken()}` } }
    );
    return result;
  },
};
