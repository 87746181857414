import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { authThunks } from "../../actions-thunks/auth-thunks";
import Button from "../utilities/element/Button";
import { utilityFns } from "../utilities/utility-functions";

import "./main-taskbar.scss";

const MainTaskbar = () => {
  const [fullnameClicked, setFullnameClicked] = useState(false);
  const { profile } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(authThunks.signoutTC());
  };

  const onFullnameClicked = () => {
    setFullnameClicked(!fullnameClicked);
  };

  return (
    <header className="taskbar-block">
      <div className="taskbar-container">
        <div className="taskbar-brand">
          <a href={utilityFns.getLandingUrl()}>
            <img
              src={utilityFns.getImageFromStore("zimtra_site_logo.png", "06")}
              alt="zimtra-logo"
            />
          </a>
        </div>
        <div onClick={onFullnameClicked} className="taskbar-collapse-block">
          <div className="taskbar-fullname-block">
            <span>{profile.firstName}</span>
            <span>{profile.lastName}</span>
          </div>
          <div className="taskbar-avatar-letters">
            {profile?.firstName?.[0] && `${profile?.firstName?.[0]}${profile?.lastName?.[0]}`.toUpperCase()}
            <img
                src={`${process.env.PUBLIC_URL}/arrow-down.png`}
                alt="shevron-icon"
                width="16"
                height="16"
                className={`dropdown-menu-icon ${
                  fullnameClicked ? "dropdown-menu-icon-inverted" : undefined
                }`}
              />
          </div>
          {fullnameClicked && (
            <Button onClick={onLogout} className="taskbar-logout-button">
              Log out
              <img
                width="24"
                height="24"
                src={`${process.env.PUBLIC_URL}/log-out.svg`}
                alt="logout"
              />
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default MainTaskbar;
