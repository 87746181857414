import { MAIL_SEND_STATUS, CHANGE_PASSWORD_TOKEN, ACTIVATION_REQUEST_STATUS,SET_AUTHENTICATION, SET_SIGN_UP_DATA } from "./types";

const initialState = {
  activationRequestStatus: null,
  emailSentStatus: null,
  authenticated: false,
  signUpData: null,
  changePasswordTokenStatus: null
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTIVATION_REQUEST_STATUS:
      return {
        ...state,
        activationRequestStatus: payload,
      };
      case SET_SIGN_UP_DATA:
      return {
        ...state,
        signUpData: payload,
      };
    case MAIL_SEND_STATUS:
      return {
        ...state,
        emailSentStatus: payload
      }
    case SET_AUTHENTICATION:
      return {
        ...state,
        authenticated: payload
      }
    case CHANGE_PASSWORD_TOKEN:
      return {
        ...state,
        changePasswordTokenStatus: payload
      }
    default:
      return state;
  }
};

export default authReducer;
