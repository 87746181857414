import React, { useEffect, useState } from "react";

import Button from "../../utilities/element/Button";
import { useDispatch, useSelector } from "react-redux";
import { authThunks } from "../../../actions-thunks/auth-thunks";
import {
  TO_SIGN_UP_CONFIRMATION,
  LOG_IN_TOKEN,
  TO_ACTIVATE_CONFIRMATION,
  TO_HOME_PAGE,
} from "../../../reducers/types";
import { setAuthenticationAC } from "../../../actions-thunks/actions";

import "./confirmation.scss";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const SignUpPage = ({ to }) => {
  const navigate = useNavigate();
  const maxCountdown = 30;
  const [activateButton, setActiveateButton] = useState(false);
  const [startCountdown, setStartCountdown] = useState(true);
  const [showCountdown, setShowCountdown] = useState(true);
  const [countdown, setCountdown] = useState(maxCountdown);

  const { signUpData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (countdown === 0) {
      setActiveateButton(() => true);
      setShowCountdown(false)
      setStartCountdown(() => false);
      setCountdown((oldCount) => maxCountdown);
    }
    if (startCountdown && countdown > 0) {
      setActiveateButton(() => false);
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
  }, [countdown, startCountdown]);

  const handleResend = () => {
    setShowCountdown(true)
    setStartCountdown(() => true);
    dispatch(authThunks.signupTC(signUpData));
  };
  const handleContinue = () => {
    if (Cookies.get(LOG_IN_TOKEN)) {
      dispatch(setAuthenticationAC(true));
      navigate(TO_HOME_PAGE);
    }
  };

  const renderTImer = () => {
    if (showCountdown)
      return <span>Resend Email ({countdown})</span>;
    return <span>Resend Email </span>;
  };

  const renderPopup = () => {
    switch (to) {
      case TO_SIGN_UP_CONFIRMATION:
        return (
          <div className="form-block-confirmation">
            <h4>Thank you for your interest in Zimtra!</h4>
            <span>We sent you an email with your activation link.</span>
            <span>This link will expire in 30 minutes.</span>
            <hr />
            <span>Did not receive an email with the activation link?</span>
            <span>Please check your spam folder, wait a few minutes or click below.</span>
            <div className="button-section">
              <Button disabled={!activateButton} onClick={handleResend}>
                {renderTImer()}
              </Button>
            </div>
          </div>
        );
      case TO_ACTIVATE_CONFIRMATION:
        return (
          <div className="form-block-confirmation">
            <h4>Thank you for completing the registration process!</h4>
            <span>
              Please continue to your application by clicking the button below.
            </span>
            <Button onClick={handleContinue}>Continue Application</Button>
          </div>
        );

      default:
        break;
    }
  };

  return renderPopup();
};

export default SignUpPage;
