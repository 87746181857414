import React from "react";
import { utilityFns } from "../utility-functions";

import "./checkbox.scss";

const Checkbox = (props) => {
  const checked = String(props.value).toLowerCase() === "true";
  const className = props.className;
  const propsForInput = { ...props };
  delete propsForInput.key;
  const randomKey = Math.random();
  return (
    <div
      className={`main-checkbox ${className} ${
        utilityFns.strToBoolean(props.isgreyed) ? "greyscale" : undefined
      } ${
        !utilityFns.strToBoolean(props.agreementstep)
          ? undefined
          : checked
          ? "unchecked-transparent"
          : "checked-yellow"
      }`}
    >
      <input
        id={randomKey}
        type="checkbox"
        {...propsForInput}
        checked={checked}
      />
      <label htmlFor={randomKey}>{props.placeholder}</label>
      {props.tooltip || null}
    </div>
  );
};

export default Checkbox;
