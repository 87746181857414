import { utilityFns } from "./utility-functions";

export const testMandatoriesFns = {
  aboutYou: (profile) => {
    const { personal, address, pep } = profile;
    const now = new Date();
    const d = new Date(profile.dateofbirth);
    const ExpMs = (365 * 18 + 5) * 86400 * 1000;
    return !!(
      utilityFns.emptyString(personal.title) &&
      (pep.pep ? !!pep.details ? true : false : true) &&
      utilityFns.emptyString(personal?.firstName) &&
      utilityFns.emptyString(personal?.lastName) &&
      utilityFns.emptyString(address?.street) &&
      utilityFns.emptyString(profile.phone) &&
      utilityFns.emptyString(address?.city) &&
      utilityFns.emptyString(address?.postalCode) &&
      utilityFns.validEnglishTextAndNumber(personal?.firstName) &&
      utilityFns.validEnglishTextAndNumber(personal?.lastName) &&
      utilityFns.validEnglishTextAndNumber(address?.city) &&
      !!address?.country && !!personal?.citizenship &&
      !!profile.dateofbirth &&
      new Date("1903", "01", "01").getTime() - 31 * 86400 * 1000 <
        d.getTime() &&
      now.getTime() - ExpMs > d.getTime()
    )
  },
  IDVerification: (profile, files) => {
    const now = new Date();
    const ExpAheadMs = 90 * 86400 * 1000;
    return !!(
      profile.idtype &&
      utilityFns.emptyString(profile.idnumber) &&
      utilityFns.validIDNumber(profile.idnumber) &&
      profile.idexpirydate !== undefined &&
      (!!profile.isexpiry ?
      now.getTime() + ExpAheadMs < new Date(profile.idexpirydate).getTime() : true) &&
      Object.keys(files).includes("photoID") &&
      Object.keys(files).includes("identity") &&
      Object.keys(files).includes("address")
    );
  },
  agreement: (state) => {
    return !!(testMandatoriesFns.aboutYou(state.profile) && 
      utilityFns.strToBoolean(state.profile.agreement1) &&
      utilityFns.strToBoolean(state.profile.agreement2) &&
      !!state.profile.signature)
  },
  businessDeal: (profile, limits) => {
    const rules = profile.agreement?.rules || [];
    return (
      Object.keys(limits || []).every(l => {
        if (!profile.overnight && l === "overnightBuyingPower") return true;
        return (
        parseInt(rules[l]) <= parseInt(limits[l].max) &&
        parseInt(rules[l]) >= parseInt(limits[l].min)
      )}) &&
      !!profile.accountType && !!profile.software &&
      !!profile.softwareType &&
      !!profile.marketdataBundle && !!profile.agreement.marketData.length
    )
  }, 
};
