import React, { useCallback, useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { useDispatch } from "react-redux";

import { profileThunks } from "../../../actions-thunks/profile-thunks";
import Button from "../element/Button";
import { utilityFns } from "../utility-functions";

import "./ToastInfoDialog.scss";

const ToastInfoDialog = ({
  toastInfo,
  setToastInfo,
  setToastInfoData,
  toastInfoData,
}) => {
  const { title, message, status, type } = toastInfo;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (status) {
      setShow(true);
      utilityFns.addRemoveScrollEventToBody(true, "wheel");
      utilityFns.addRemoveScrollEventToBody(true, "touchmove");
    } else {
      utilityFns.addRemoveScrollEventToBody(false, "wheel");
      utilityFns.addRemoveScrollEventToBody(false, "touchmove");
    }
    return () => {
      utilityFns.addRemoveScrollEventToBody(false, "wheel");
      utilityFns.addRemoveScrollEventToBody(false, "touchmove");
    };
  }, [status]);

  const toastTurnOff = () => {
    setShow(false);
    setToastInfo("", "", false, "");
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      toastTurnOff();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const onConfirmHandler = () => {
    switch (type) {
      case "remove-image":
        dispatch(
          profileThunks.deleteScan({
            filename: toastInfoData,
            kind: toastInfoData,
          })
        );
        setToastInfoData("");
        break;

      default:
        break;
    }
    toastTurnOff();
  };

  const renderToastOption = () => {
    if (title === "Confirming action") {
      return (
        <Toast className="toast-main" onClose={toastTurnOff} show={show}>
          <Toast.Body className="body">
            {message ? message : "Message"}
          </Toast.Body>
          <div className="toast-dialog-buttons">
            <Button onClick={toastTurnOff}>Cancel</Button>
            <Button onClick={onConfirmHandler}>Confirm</Button>
          </div>
        </Toast>
      );
    }
    if (title === "Notification") {
      return (
        <Toast
          className="toast-main"
          onClose={toastTurnOff}
          show={show}
          onClick={toastTurnOff}
        >
          <Toast.Header className="header">
            <div className="header-title">{title ? title : "Title"}</div>
            <button className="header-button" onClick={toastTurnOff}>
              X
            </button>
          </Toast.Header>
          <hr style={{ marginBottom: "10px" }} />
          <Toast.Body className="body">
            {message ? message : "Message"}
          </Toast.Body>
        </Toast>
      );
    }
  };

  if (show)
    return (
      <div className="toast-wrapper">
        <div className="greyed-area"></div>
        {renderToastOption()}
      </div>
    );
};

export default React.memo(ToastInfoDialog);
