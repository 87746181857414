import React, { useEffect, useMemo } from "react";

import Checkbox from "../../utilities/element/Checkbox";
import Input from "../../utilities/element/Input";
import { utilityFns } from "../../utilities/utility-functions";
import AboutYouStep from "../AboutYou/AboutYou";

import "./agreement.scss";
import { getBundleName } from "../BusinessDeal/helpers";

const SHARES = "shares";
const PAYOUT = "payout";

const AgreementStep = ({ localState, setLocalState }) => {
  const { volumePayments, tradingPlatform, profile, agreementTemplate, rates } =
    localState;

  useEffect(() => {
    const countryrates = rates.countryrates.filter(
      (cr) => cr.accountTypeRef === profile.accountType
    );

    const vp = (
      countryrates.length > 0
        ? countryrates
        : rates.countryrates.filter((x) => x.country === "*")
    ).sort((a, b) => (a.min < b.min ? -1 : a.min > b.min ? 1 : 0));

    const tpIndex = rates.tradingplatforms.findIndex((tp) => {
      return tp.name === profile.tradingplatform;
    });

    const tradingPlatform =
      tpIndex !== -1 ? rates.tradingplatforms[tpIndex] : {};
    const volumePayments = vp.length > 0 ? vp : [];

    const updateMarketDataEntitlements = (profile, localState2) => {
      const fromMarketObjects = (marketObjects) => {
        return marketObjects.map((obj) => `${obj.group}-${obj.market}`);
      };
      if (profile.agreement.marketData) {
        const unitedMarkets = fromMarketObjects(profile.agreement.marketData);
        localState2.profile.markets = [...unitedMarkets];
        localState2.profile.marketdataBundle =
          getCurrentMarketBundle(localState2);
        return { ...localState2 };
      }
    };

    let localState2 = { ...localState };
    localState2 = updateMarketDataEntitlements(profile, localState2);

    setLocalState({
      ...localState2,
      countryrates,
      volumePayments,
      tradingPlatform,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentMarketBundle = (localState2) => {
    const mdp = rates?.marketdataproviders?.filter((p) => {
      return p.tradingPlatformRefs.includes(
        utilityFns.findCurrentTradingPlatform(profile, rates)?.reference
      );
    });
    return getBundleName({
      marketdataProviders: mdp,
      currentAccount: localState2.profile,
      tradingPlatforms: rates?.tradingplatforms,
      marketBundles: rates.bundles,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setLocalState({
        ...localState,
        profile: { ...profile, [name]: checked },
      });
    } else {
      setLocalState({ ...localState, profile: { ...profile, [name]: value } });
    }
  };
  const infoConfirmMessage = `I acknowledge that the foregoing and all other information provided during the
  account application process is true and correct and agree to notify Zimtra by
  email or otherwise of any material changes therein. I authorize Zimtra to confirm
  the accuracy of the information provided by me as Zimtra deems necessary.`;
  const agreementConfirmMessage =
    "I have read and acknowledged terms of the Professional Trader Service Agreement";

  const money = (v, frac = 0) => {
    return parseFloat(v).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: frac,
    });
  };

  const formatValue = (v, formats) => {
    if (formats && formats.length > 0) {
      switch (formats[0]) {
        case "money":
          const digits = formats[1] || 0;
          return money(v, digits);
        default:
      }
    }
    return v;
  };
  const generateTierValue = (t, spec) => {
    var value;
    switch (spec[0]) {
      case "commission":
        value = t.commission;
        break;
      case "payout":
        value = `${t.payout * 100.0}%`;
        break;
      case "overnight":
        value = `${t.interest.fed ? "FED+" : ""}${t.interest.percent.toFixed(
          2
        )}%`;
        break;
      case "software":
        const tp = utilityFns.findCurrentTradingPlatform(profile, rates);
        const tpPrice = utilityFns.calculatePlatformPriceWithConditions(
          tp,
          profile
        );
        const softwareprice =
          typeof tpPrice !== "undefined"
            ? `$${
                t.software.base
                  ? tpPrice + t.software.change
                  : t.software.change
              }`
            : "platform?";
        value = softwareprice;
        break;
      default:
        value = t[spec[0]];
    }

    return {
      className: spec[1] || spec[0],
      value: formatValue(value, spec.slice(2)),
    };
  };
  const moneyshort = (v, frac = 1) => {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: frac,
      notation: "compact",
      compactDisplay: "short",
    }).format(v);
  };
  const templateProperties = (text) => {
    const generateTiers = (spec) => {
      const arr = volumePayments.map((t, index) => {
        const tv = generateTierValue(t, spec);
        return (
          <div className="tier-level-wrapper" key={index}>
            <span className="min">{moneyshort(t.min, 2)}</span>
            <span className="max">
              {t.max > 0 ? moneyshort(t.max, 2) : " ∞ "}
            </span>
            <span className={tv.className}>{tv.value}</span>
          </div>
        );
      });

      return (
        <div className="tier-levels">
          <div className="tier-level-wrapper header">
            <span className="min">volume low</span>
            <span className="max">volume high</span>
            <span className="value">{spec[0]}</span>
          </div>
          {arr}
        </div>
      );
    };

    // parse text <.*>
    // get template text from it
    // replace with properties
    // to it globaly on text
    const rp = (p) => {
      if (!p.match(/^<.*>$/)) {
        return p;
      }

      const key = p
        .slice(1, -1)
        .split(/,/)
        .map((v) => v.trim());
      if (key.length > 0 && key[0].length > 0) {
        if (typeof profile[key[0]] !== "undefined") {
          var v = profile[key[0]];
          if (key.length > 1) {
            v = (
              <div className="schedule-amount">
                {formatValue(v, key.slice(1))}
              </div>
            );
          }
          return v;
        } else {
          if (key[0] === "tiers") {
            return generateTiers(key.slice(1));
          }
        }
      } else {
        return p;
      }
    };

    const ss = text.split(/(<[\w\s\t\d.,]+>)/);
    return ss.map(rp);
  };

  const marketdataBundleName = getBundleName({
    marketdataProviders: rates.marketdataproviders,
    currentAccount: profile,
    tradingPlatforms: rates.tradingplatforms,
    marketBundles: rates.bundles,
  });

  const renderAgreementText = (text) => {
    if (!text) {
      return null;
    }

    return text.split("\n").map((e, index) => {
      var className = "simple";

      // Number. - as header
      if (e.match(/^\s?\d+\./)) {
        className = "header";
      }

      // CAPS - as highlight
      if (e.match(/^[A-Z ,.]+$/)) {
        className = "highlight";
      }

      const calculated = templateProperties(e); //simple-tier
      // substitute properties
      return (
        <div className={className} key={index}>
          {calculated}
        </div>
      );
    });
  };

  const updateNegativeValues = (obj) => {
    const numericValue = parseFloat(obj.value.replace(/[^0-9.-]/g, ""));

    // If the number is less than 0, set it to 0 but keep the currency or symbol
    if (numericValue < 0) {
      obj.value = obj.value.replace(numericValue, 0);
    }

    return obj;
  };

  const marketdataproviders = useMemo(() => {
    const tradingPlatformReference =
      utilityFns.findCurrentTradingPlatform(profile, rates)?.reference || "";
    const result = rates?.marketdataproviders?.filter((p) => {
      return p.tradingPlatformRefs.includes(tradingPlatformReference);
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rates, tradingPlatform, localState, profile.accountType]);

  const renderSchedules = () => {
    const renderScheduleA = () => {
      return (
        <div className="schedule-a">
          <span className="font-bold text-left l-h4">SCHEDULE A</span>

          <div className="flex-between">
            <span>Performance Bond Amount:</span>
            <span className="font-bold">
              {formatValue(profile.agreement.rules.performanceBond, [
                "money",
                "2",
              ])}
            </span>
          </div>
          <div className="flex-between">
            <span>Initial Buying Power:</span>
            <span className="font-bold">
              {formatValue(profile.agreement.rules.initialBuyingPower, [
                "money",
                "2",
              ])}
            </span>
          </div>
          <div className="flex-between">
            <span>Approved Overnight Buying Power:</span>
            <span className="font-bold">
              {formatValue(profile.agreement.rules.overnightBuyingPower || 0, [
                "money",
                "2",
              ])}
            </span>
          </div>
          <div className="flex-between">
            <span>Maximum Daily Loss Limit:</span>
            <span className="font-bold">
              {formatValue(profile.agreement.rules.maximumDailyLoss, [
                "money",
                "2",
              ])}
            </span>
          </div>
          <div className="flex-between">
            <span>Maximum Trader Balance Draw Down:</span>
            <span className="font-bold">50%</span>
          </div>
        </div>
      );
    };

    const renderLeftTablePart = (leftName, forPayout) => {
      const leftValues = () => {
        if (volumePayments.length > 0) {
          const filterdVP = volumePayments.filter(
            (vp) => vp.accountTypeRef === profile.accountType
          );
          const tiers = filterdVP.find(
            (i) => i.classifier === (forPayout ? PAYOUT : SHARES)
          ).tiers;
          const formatMaxValue = (tier) => {
            let returnValue = "∞ ";
  
            if (tier.minSharePrice) {
              returnValue += `(≥ $${tier.minSharePrice})`;
            } else if (tier.maxSharePrice) {
              returnValue += `(< $${tier.maxSharePrice})`;
            }
  
            return <span className="grey-color">{returnValue}</span>;
          };
  
          return (
            <div className="monthly-trading-volumes flex-column">
              {tiers.map((t) => {
                return (
                  <span>
                    {utilityFns.moneyshort(t.min, 2, true)} -{" "}
                    {t.max > 0
                      ? utilityFns.moneyshort(t.max, 2, true)
                      : formatMaxValue(t)}
                  </span>
                );
              })}
            </div>
          );
        }
      };
      return (
        <div className="flex-column">
          <span className="font-bold">{leftName}</span>
          {leftValues()}
        </div>
      );
    };
    const renderRightTablePart = (rightName, spec, forPayout) => {
      const rightValues = () => {
        if (volumePayments.length > 0) {
          const tiers = volumePayments.find(
            (i) => i.classifier === (forPayout ? PAYOUT : SHARES)
          ).tiers;
          return tiers.map((t, index) => {
            const tv = generateTierValue(t, spec);
            if (tv.className === "commission") {
              const value =
                tv.value % 10 === 0 ? `${tv.value / 10000}0` : tv.value / 10000;
              return (
                <span key={index} className={tv.className}>
                  {value}
                </span>
              );
            }
            if (tv.className === "overnight") {
              const value = tv.value.replace("%", "");
              return (
                <span key={index} className={tv.className}>
                  {value}
                </span>
              );
            }
            const updatedData = updateNegativeValues(tv);
            return (
              <span key={index} className={tv.className}>
                {updatedData.value}
              </span>
            );
          });
        }
      };
      return (
        <div className="flex-column">
          <span className="font-bold">{rightName}</span>
          {rightValues()}
        </div>
      );
    };

    const renderTraderPercentage = () => {
      return (
        <div className="trader-percentage">
          <hr />
          <p>
            Percentage payout determined by a monthly Net Trading Profile
            according to the following schedule:
          </p>
          <div className="flex">
            {renderLeftTablePart("Monthly Trading Profit ($)", true)}
            {renderRightTablePart("Payout (%)", ["payout"], true)}
          </div>
        </div>
      );
    };
    const renderClearingCommision = () => {
      return (
        <div className="clearing-commision">
          <hr />
          <p>
            Clearing commission determined by a monthly traded volume according
            to the following schedule:{" "}
          </p>
          <div className="flex">
            {renderLeftTablePart("Monthly Trading Volumes (shares)")}
            {renderRightTablePart("Commission Rates ($)", ["commission"])}
          </div>
        </div>
      );
    };
    const renderOvernightFee = () => {
      return (
        <div className="overnight-fee">
          <hr />
          <p>
            Overnight fee determined by a monthly traded volume according to the
            following schedule:
          </p>
          <div className="flex">
            {renderLeftTablePart("Monthly Trading Volumes (shares)")}
            {renderRightTablePart("Overnight Rates (%)", ["overnight"])}
          </div>
          <hr />
          <p>
            ECN fees and rebates, as well as other exchange charges will be
            billed at prevailing market rates as advertised by the exchanges.
          </p>
        </div>
      );
    };
    const renderSoftwareFee = () => {
      // eslint-disable-next-line array-callback-return
      const MDs = marketdataproviders
        .map((md) => {
          const marketId = utilityFns.getMarketId(md);
          const v = profile.markets.includes(marketId);
          if (v) return md;
          return null;
        })
        .filter((n) => n);
      const renderMDSubscriptions = () => {
        return (
          <div className="market-data-subscription-sources">
            <span>Market data subscription sources:</span>
            <ul>
              {MDs.map((m, index) => {
                return (
                  <li className="market-subscription" key={index}>
                    <span className="name">{m.name}</span>
                    <span className="price">
                      {utilityFns.money(m.price, 2)}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      };
      const totalMDPrice = MDs.reduce((acc, item) => {
        return acc + item.price;
      }, 0);

      const getTiers = (forPayout) => {
        if (volumePayments.length > 0) {
          const filterdVP = volumePayments.filter(
            (vp) => vp.accountTypeRef === profile.accountType
          );
          const tiers = filterdVP.find(
            (i) => i.classifier === (forPayout ? PAYOUT : SHARES)
          ).tiers;
          return tiers;
        }
      };

      const getMonthlySoftwareFeePrice = () => {
        let foundPrice = 0;
        getTiers(false)?.forEach((tier) => {
          const tv = generateTierValue(tier, ["software"]);
          if (tv && tv.value && Number(tv.value.substring(1)) > 0) {
            foundPrice = tv.value;
          }
        });
        return foundPrice;
      } 

      return (
        <div className="software-fee">
          <hr />
          <p>
            Software fee determined by a monthly traded volume according to the
            following schedule:
          </p>
          <div className="flex-between semi-m-bot trading-platform">
            <span>Trader's platform:</span>
            <span className="font-bold">
              {utilityFns.findCurrentTradingPlatform(profile, rates)?.name}
              {profile.tradingplatform}
            </span>
          </div>
          {profile.accountType === "zimtraexpert" ? (
            <div className="flex semi-m-bot monthly-trading-volumes">
              {renderLeftTablePart("Monthly Trading Volumes (shares)")}
              {renderRightTablePart("Monthly Cost", ["software"])}
            </div>
          ) : (
            <div className="flex-between semi-m-bot trading-platform">
              <span>Monthly Software Fee:</span>
              <span className="font-bold">
                {getMonthlySoftwareFeePrice()}
              </span>
            </div>
          )}
          <div className="flex-between market-data-subscription">
            <span>Market data subscription option:</span>
            <span className="font-bold">{marketdataBundleName}</span>
          </div>
          {renderMDSubscriptions()}
          <div className="flex-between market-data-price-summary">
            <span>Market data price summary:</span>
            <span className="font-bold">
              ${Math.round(totalMDPrice * 100) / 100}
            </span>
          </div>
          <p className="market-data-price-summary-text">
            Exchange market data is billed at prevailing market rates.
          </p>
        </div>
      );
    };

    return (
      <div className="agreement-schedules">
        {renderScheduleA()}
        {renderTraderPercentage()}
        {renderClearingCommision()}
        {renderOvernightFee()}
        {renderSoftwareFee()}
      </div>
    );
  };

  const renderTodayDate = () => {
    const year = new Date().getFullYear();
    const month = new Date().toLocaleString("default", { month: "long" });
    const day = new Date().getDate();

    const formatAMPM = (date) => {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    };
    return (
      <div className="onb-date">{`${year} ${month} ${day} ${formatAMPM(
        new Date()
      )}`}</div>
    );
  };

  return (
    <div className="agreement-wrapper">
      <h4 id="reviewagreement">Review Application</h4>
      <p className="header-text">
        Please review and confirm the following information and correct any
        errors before proceeding.
      </p>
      <hr />
      <AboutYouStep
        fromAgreement={true}
        localState={localState}
        setLocalState={setLocalState}
      />
      <Checkbox
        className="agreement1"
        name="agreement1"
        agreementstep="true"
        placeholder={infoConfirmMessage}
        value={profile.agreement1}
        onChange={handleChange}
      />
      <br />
      <hr />
      <h4>Trader Service Agreement</h4>
      <div className="trading-service-agreement">
        {renderAgreementText(agreementTemplate?.documentBody)}
      </div>
      <Checkbox
        name="agreement2"
        className="agreement2"
        agreementstep="true"
        placeholder={agreementConfirmMessage}
        value={profile.agreement2}
        onChange={handleChange}
      />
      <hr />
      {renderSchedules()}
      <hr />
      <br />
      <p className="footer-text">
        The monthly pay date for Trader compensation and/or account
        reconciliation or closure will take place on the 1st of the following
        trading month (i.e. trading profits made in June are paid out no later
        than August 1).
      </p>
      <div className="signature-wrapper">
        <Input
          name="signature"
          label="Signature"
          placeholder="Required"
          value={profile.signature || ""}
          onChange={handleChange}
          required
        />
        <div className="signature-dated">
          <div className="dated">Dated</div>
          {renderTodayDate()}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default AgreementStep;
