import React from "react";
import { utilityFns } from "../utility-functions";
import "./element-styles.scss";


const Input = (props) => {
  const mandatory =
    typeof props.required !== "undefined" &&
    utilityFns.strToBoolean(props.required) &&
    (!props.value || props.value.trim().length === 0);
  const valid =
    (typeof props.min === "undefined" ||
      parseFloat(props.value) >= parseFloat(props.min)) &&
    (typeof props.max === "undefined" ||
      parseFloat(props.value) <= parseFloat(props.max)) &&
    (!props.onValidate || props.onValidate(props.value));

  const propsForInput = {...props};
  delete propsForInput.onValidate;

  return (
    <div className="input-wrapper">
      {props.label && <span className="label">{props.label}</span>}
      <input
        type={props.name === "pepcomment" ? "textarea": "text"}
        {...propsForInput}
        className={`${props.whitebg ? "white-bg" : undefined} ${utilityFns.strToBoolean(props.isgreyed) ? "greyed-field" : undefined} ${props.disabled ? "apply-opacity" : undefined}`}
        mandatory={mandatory.toString()}
        valid={valid.toString()}
      />
      {!(!props.onValidate || props.onValidate(props.value)) && (
        <div className={"not-valid-form-message"}>
          {props.messagefornotvalid}
        </div>
      )}
    </div>
  );
};

export default Input;
