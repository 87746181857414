import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import EyeIcon from "../../utilities/EyeIcon";
import {
  SIGN_IN_ERROR,
  TO_RECOVER_PASSWORD,
  CHECK_TOKEN_ERROR,
  TO_HOME_PAGE,
} from "../../../reducers/types";
import { authThunks } from "../../../actions-thunks/auth-thunks";
import { setErrorAC } from "../../../actions-thunks/actions";
import Button from "../../utilities/element/Button";
import { utilityFns } from "../../utilities/utility-functions";

import "./SignIn.scss";

const SignInPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [localState, setLocalState] = useState({
    username: "",
    password: "",
    rememberme: true,
    redirect: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username, password, rememberme } = localState;
  const { authenticated } = useSelector((state) => state.auth);
  const { error, loading } = useSelector((state) => state.utility);

  useEffect(() => {
    dispatch(
      setErrorAC({
        type: SIGN_IN_ERROR,
        message: "",
      })
    );
    if (!window.location.hostname === "localhost") {
      utilityFns.redirectToLanding("logout");
    }
  }, [dispatch]);

  useEffect(() => {
    if (authenticated) {
      navigate(TO_HOME_PAGE);
    } else dispatch(authThunks.checkTokenTC());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (error?.type === SIGN_IN_ERROR || error?.type === CHECK_TOKEN_ERROR) &&
      error?.message?.length > 0
    ) {
      dispatch(setErrorAC({ type: SIGN_IN_ERROR, message: "" }));
    }
    if (name === "rememberme") {
      setLocalState({ ...localState, [name]: e.target.checked });
    } else setLocalState({ ...localState, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: localState.username,
      password: localState.password,
    };
    dispatch(authThunks.signinTC(userData, localState.rememberme));
  };

    return (
      <div className="form-block-signin">
        <Form className="form-wrapper">
          <h4>Sign in</h4>
          <Form.Group className="form-group-block mb-3">
            <Form.Label className="form-label">Email</Form.Label>
            <Form.Control
              value={username}
              onChange={handleChange}
              name="username"
              type="email"
              placeholder="Required"
            />
          </Form.Group>

          <Form.Group className="form-group-block mb-3">
            <Form.Label className="form-label">Password</Form.Label>
            <Form.Control
              value={password}
              onChange={handleChange}
              name="password"
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Required"
            />
            <span
              className="see-pasword"
              onClick={() => setShowPassword(!showPassword)}
            >
              {<EyeIcon show={showPassword} />}
            </span>
          </Form.Group>
          <div className="checkbox-sign-in">
            <Form.Group className="spaced-between form-checkbox">
              <Form.Check
                name="rememberme"
                onChange={handleChange}
                checked={rememberme}
                type="checkbox"
                label="Stay signed in"
              />
              <NavLink to={TO_RECOVER_PASSWORD}>Forgot my password</NavLink>
            </Form.Group>
          </div>
          <div className="button-error-section">
            {(error?.type === SIGN_IN_ERROR ||
              error?.type === CHECK_TOKEN_ERROR) &&
              error?.message?.length > 0 && (
                <span className="error-message">{error.message}</span>
              )}
            <Button
              onClick={handleSubmit}
              disabled={loading || !username || !password}
            >
              Sign in
              {loading && <Spinner className="spinner" animation="grow" />}
            </Button>
          </div>
        </Form>
      </div>
    );
};

export default React.memo(SignInPage);
