import React, {useEffect} from "react";
import { Route, Routes, Navigate, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  TO_SIGN_UP,
  TO_HOME_PAGE,
  TO_SIGN_IN,
  TO_RESET_PASSWORD,
  TO_ACTIVATE_CONFIRMATION,
  TO_SIGN_UP_CONFIRMATION,
  TO_ACTIVATION_PAGE,
  TO_RECOVER_PASSWORD,
  LOGOUT,
} from "../reducers/types";
import ProtectedRoute from "./ProtectedRoute";
import NotAuthLayout from "../ui/NotAuthLayout";
import AuthLayout from "../ui/AuthLayout";
import PasswordRecoveryPage from "../ui/auth/password-recover/PasswordRecoveryPage";
import { triggerRedirectAC } from "../actions-thunks/actions";
import LogoutPage from "../ui/auth/Logout-page";



const AllRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { triggerRedirect } = useSelector((state) => state.utility);
  const token = searchParams.get("token");
  
  useEffect(() => {
    if (triggerRedirect.status) {
      navigate(triggerRedirect.direction);
      dispatch(triggerRedirectAC({ direction: "", status: false }));
    }
  }, [triggerRedirect, navigate, dispatch]);

  return (
    <Routes>
        <Route index element={ <Navigate to={TO_HOME_PAGE} />} />
        <Route path={TO_SIGN_UP} element={ <NotAuthLayout destination={TO_SIGN_UP} />}/>
        <Route path={TO_SIGN_IN} element={ <NotAuthLayout destination={TO_SIGN_IN}/>}/>
        <Route path={TO_ACTIVATION_PAGE} element={ <NotAuthLayout destination={TO_ACTIVATION_PAGE} token={token} />} />
        <Route path={TO_SIGN_UP_CONFIRMATION} element={ <NotAuthLayout destination={TO_SIGN_UP_CONFIRMATION} />} />
        <Route path={TO_ACTIVATE_CONFIRMATION} element={ <NotAuthLayout destination={TO_ACTIVATE_CONFIRMATION} />} />
        <Route path={TO_RESET_PASSWORD} element={ <NotAuthLayout destination={TO_RESET_PASSWORD} token={token} />} />
        <Route path={TO_RECOVER_PASSWORD} element={ <PasswordRecoveryPage />} />
        <Route path={LOGOUT} element={ <LogoutPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path={TO_HOME_PAGE} element={<AuthLayout />}/>
        </Route>
        <Route path="*" element={ <Navigate to={TO_HOME_PAGE}/>} replace />
    </Routes>
  );
};

export default React.memo(AllRoutes);
