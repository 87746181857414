import React from "react";
import { utilityFns } from "../utility-functions"

import "./Dropdown.scss";

const Dropdown = (props) => {
  const renderOptions = (opts) => {
    return (opts || []).map((o, i) => (
      <option key={`d-${i}`} value={o.value || o.text} disabled={o.disabled}>
        {o.text}
      </option>
    ));
  };

  const mandatory =
    typeof props.required !== "undefined" &&
    utilityFns.strToBoolean(props.required) &&
    !props.value;
  return (
    <div className="dropdown-wrapper">
      {props.label && <span className="label">{props.label}</span>}
      <select
        {...props}
        mandatory={mandatory.toString()}
        className={`${utilityFns.strToBoolean(props.isgreyed) ? "greyed-field" : undefined}`}
      >
        <option value="" disabled>
          {props.placeholder || "Pick out"}
        </option>
        {renderOptions(props.options)}
      </select>
    </div>
  );
};

export default Dropdown;
