import { 
    GET_ERRORS, IS_DEMO_ACCOUNT,
    SET_LOADING,
    ACTIVATION_REQUEST_STATUS,
    SET_AUTHENTICATION,
    UPDATE_PROFILE,
    UPDATE_FILES,
    MAIL_SEND_STATUS,
    SET_SIGN_UP_DATA,
    DELETE_FILE,
    UPDATE_CERTAIN,
    CHANGE_PASSWORD_TOKEN,
    SET_DATA_REQUESTED,
    SET_SHOW_SPINNER,
    UPDATE_RATES,
    SET_IS_DOWNLOADING_IMAGE,
    SET_TOAST,
    TRIGGER_REDIRECT,
    CREATE_ACCOUNT,
    UPDATE_PROFILE_WITH_SUMSUB_DATA,
    SET_ACCESS_TOKEN,
    DISABLE_SUBMIT_BUTTON} from "../reducers/types";

//auth actions
export const setAuthenticationAC = (payload) => ({type: SET_AUTHENTICATION,payload});
export const setSignUpDataAC = (payload) => ({type: SET_SIGN_UP_DATA,payload});
export const activationRequestStatusAC = (payload) => ({type: ACTIVATION_REQUEST_STATUS,payload});
export const emailSendStatusAC = (payload) => ({type: MAIL_SEND_STATUS,payload});
export const changePasswordTokenStatusAC = (payload) => ({type: CHANGE_PASSWORD_TOKEN,payload});

//sumsub actions
export const setAccessTokenAC = (payload) => ({type: SET_ACCESS_TOKEN ,payload});

//profile actions
export const updateProfileAC = (payload) => ({type: UPDATE_PROFILE,payload});
export const updateProfileWithSumsubDataAC = (payload) => ({type: UPDATE_PROFILE_WITH_SUMSUB_DATA,payload});
export const setDataRequestedAC = (payload) => ({type: SET_DATA_REQUESTED,payload});
export const updateFilesAC = (payload) => ({type: UPDATE_FILES,payload});
export const deleteFileAC = (payload) => ({type: DELETE_FILE,payload});
export const updateCertainItemsAC = (payload) => ({type: UPDATE_CERTAIN,payload});
export const updateRatesAC = (payload) => ({type: UPDATE_RATES,payload});
export const createAccountAC = (payload) => ({type: CREATE_ACCOUNT,payload});
export const seIsDemoAccountAC = (payload) => ({type: IS_DEMO_ACCOUNT,payload});


//utility actions
export const setLoadingAC = (payload) => ({type: SET_LOADING,payload});
export const setShowSpinnerAC = (payload) => ({type: SET_SHOW_SPINNER,payload});
export const setIsDownloadingImageAC = (payload) => ({type: SET_IS_DOWNLOADING_IMAGE,payload});
export const setErrorAC = (payload) => ({type: GET_ERRORS,payload});
export const setToastInfoAC = (payload) => ({type: SET_TOAST,payload});
export const setDisableSubmitButtonAC = (payload) => ({type: DISABLE_SUBMIT_BUTTON,payload});
export const triggerRedirectAC = (payload) => ({type: TRIGGER_REDIRECT,payload});


