import {
  SET_LOADING,
  GET_ERRORS,
  SET_TOAST,
  TRIGGER_REDIRECT,
  SET_SHOW_SPINNER,
  SET_IS_DOWNLOADING_IMAGE,
  DISABLE_SUBMIT_BUTTON,
} from "./types";

const initialState = {
  loading: false,
  isDownloading: [],
  showSpinner: false,
  error: null,
  toastInfo: {
    title: "",
    message: "",
    status: false,
    type: "",
  },
  triggerRedirect: {
    direction: "",
    status: false,
  },
  disableSubmitButton: false,
};

const utilityReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ERRORS:
      return {
        ...state,
        error: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_SHOW_SPINNER:
      return {
        ...state,
        showSpinner: payload,
      };
    case SET_IS_DOWNLOADING_IMAGE:
      return {
        ...state,
        isDownloading: payload,
      };
    case SET_TOAST:
      return {
        ...state,
        toastInfo: payload,
      };
    case TRIGGER_REDIRECT:
      return {
        ...state,
        triggerRedirect: payload,
      };
      case DISABLE_SUBMIT_BUTTON:
      return {
        ...state,
        disableSubmitButton: payload,
      };
    default:
      return state;
  }
};

export default utilityReducer;
