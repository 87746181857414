import { AuthAPI, instance } from "./auth-api";

export const SumSubAPI = {
  getAccessToken: async () => {
    const response = await instance.get("registration/v2/sumsub-token", {
        headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
    });
    return response;
  },
  syncBack: async () => {
    const response = await instance.post("registration/v2/sumsub-sync", {}, {
        headers: { Authorization: `Bearer ${AuthAPI.getToken()}` },
    });
    return response;
  },
};

