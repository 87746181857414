import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import Button from "../../utilities/element/Button";
import { emailSendStatusAC } from "../../../actions-thunks/actions";
import { authThunks } from "../../../actions-thunks/auth-thunks";
import {
  PASSWORD_RECOVERY_ERROR,
  TO_SIGN_IN,
  SUCCESS,
} from "../../../reducers/types";

import "./password-recovery.scss";
import { utilityFns } from "../../utilities/utility-functions";

const PasswordRecoveryPage = () => {
  const maxCountdown = 30;

  const [localState, setLocalState] = useState({
    email: "",
  });

  const [activateButton, setActiveateButton] = useState(true);
  const [startCountdown, setStartCountdown] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [onceSent, setOnceSent] = useState(false);
  const [countdown, setCountdown] = useState(maxCountdown);

  const { emailSentStatus } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.utility);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(emailSendStatusAC({ type: SUCCESS, message: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (countdown === 0) {
      setActiveateButton(true);
      setShowCountdown(false);
      setStartCountdown(false);
      setCountdown((oldCount) => maxCountdown);
    }
    if (startCountdown && countdown > 0) {
      setActiveateButton(false);
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
  }, [countdown, startCountdown]);

  const { email } = localState;

  useEffect(() => {
    if (!startCountdown) {
      setActiveateButton(utilityFns.validEmail(email))
    }
  }, [email, startCountdown]);

  const getDataFromServer = (e) => {
    e.preventDefault();
    setShowCountdown(true);
    setOnceSent(true);
    setStartCountdown(() => true);
    dispatch(authThunks.recoverPasswordTC({ email }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ ...localState, [name]: value });
  };
  const renderTImer = () => {
    if (showCountdown)
      return <span>{onceSent ? "Send again" : "Send"} ({countdown})</span>;
    return <span>{onceSent ? "Send again" : "Send"}</span>;
  };

  return (
    <div className="form-block-recovery">
      <Form className="form-wrapper">
        <h4>Send password reset link to</h4>
        <Form.Group
          className="form-group-block mb-3"
        >
          <Form.Control
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder="email"
          />
        </Form.Group>
        <div className="button-signin">
          <Button onClick={getDataFromServer} variant="primary" disabled={!activateButton}>
            {renderTImer()}
          </Button>
          <div className="navigate-to-login-block">
            <span>Already have a login?</span>
            <NavLink to={TO_SIGN_IN}>Sign in</NavLink>
          </div>
        </div>
        {emailSentStatus?.type === SUCCESS && !!emailSentStatus.message.length && (
          <div className="pass-recovery-message-status">
            <span>If {emailSentStatus.message} is registered, you will receive password reset link.</span>
          </div>
        )}
      </Form>
      {error?.type === PASSWORD_RECOVERY_ERROR && error.message.length > 0 && (
        <span className="error-message">
          {error.find((i) => i.type === PASSWORD_RECOVERY_ERROR)?.message}
        </span>
      )}
    </div>
  );
};

export default PasswordRecoveryPage;
