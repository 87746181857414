import React, { useState, useRef, useEffect } from "react";
import { utilityFns } from "../utility-functions";
import Button from "./Button";
import { Spinner } from 'react-bootstrap';

import "./UploadFile.scss";

const UploadFile = (props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const [outletBlockWidth, setOutletBlockWidth] = useState(0);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    setOutletBlockWidth(
      document.getElementsByClassName("outlet-block")[0]?.offsetWidth
    );
    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);

  useEffect(() => {
    if (previewOpen) {
      utilityFns.addRemoveScrollEventToBody(true, "wheel");
      utilityFns.addRemoveScrollEventToBody(true, "touchmove");
    } else {
      utilityFns.addRemoveScrollEventToBody(false, "wheel");
      utilityFns.addRemoveScrollEventToBody(false, "touchmove");
    }
    return () => {
      utilityFns.addRemoveScrollEventToBody(false, "wheel");
      utilityFns.addRemoveScrollEventToBody(false, "touchmove");
    };
  }, [previewOpen]);

  const handleUploadClick = (event) => {
    hiddenFileInput.current.click();
  };

  const hangleShowPreview = (isExample) => {
    if (isExample) {
      setPreviewOpen(!previewOpen);
      setShowExample(true);
    } else {
      setShowExample(false);
      setPreviewOpen(!previewOpen);
    }
  };

  const isPDFChecker = (file) => {
    return file?.split(";")[0] === "data:application/pdf";
  };

  const renderImage = (img) => {
    return (
        <img
          onClick={() => !isPDFChecker(props.image) && hangleShowPreview()}
          src={
            isPDFChecker(img)
              ? utilityFns.getImageFromStore("icon-pdf.png", "06")
              : img
          }
          style={
            isPDFChecker(props.image)
              ? { cursor: "inherit", objectFit: "contain" }
              : null
          }
          alt="document"
        />
    );
  };

  const renderLowerButtons = () => {
    return (
      <div className="example-remove-buttons">
        {!!props.image && (
          <Button
            className="example-button-item"
            onClick={() => hangleShowPreview(true)}
          >
            Example
          </Button>
        )}
        {!!props.image && (
          <Button
            className="remove-button-item"
            onClick={() => props.removeAttachmentHandler(props.fileItem)}
          >
            Clear
          </Button>
        )}
      </div>
    );
  };

  const downHandler = (e) => {
    setShowExample(false);
    setPreviewOpen(false);
  };

  const setIsUploading = () => {
    return props.isUploading[props.name]
  }
  const setIsDonwloading = () => {
    if (!props.image) {
      const found = props.isDownloading.find(n => n.name === props.name);
      if (found) return found.status
      return false
    } else return false
  };

  const renderPreview = () => {
    if (previewOpen) {
      return (
        <div className="fullpreview" onClick={() => hangleShowPreview()}>
          <img
            className="image"
            src={showExample ? props.example : props.image}
            alt=" "
          />
        </div>
      );
    }
    return null;
  };

  const mandatory =
    typeof props.required !== "undefined" &&
    utilityFns.strToBoolean(props.required) &&
    !props.image;

  const renderUploadButton = () => {
    return (
      <div className="selector">
        <Button onClick={handleUploadClick}>
          <div className="upload-image-label">{props.label}</div>
          <div className="upload-image-explanation">{props.placeholder2}</div>
          <img alt="cloud " src={utilityFns.getImageFromStore("cloud-computing.png", "06")} />
        </Button>
        <input
          ref={hiddenFileInput}
          onClick={(e) => {e.target.value = ""}}
          type="file"
          {...props}
          style={{ display: "none" }}
        />
      </div>
    );
  };

  const renderExample = () => {
    return (
      <div className={
        `selector 
        ${setIsUploading() ? "semi-transp-bg" : undefined}`
      }>
        {props.image && props.image.length > 68
          ? renderImage(props.image)
          : <img
              className="example-image"
              onClick={() => hangleShowPreview(true)}
              src={props.example}
              alt="example"
            />
        }
        {(setIsUploading() ||
          setIsDonwloading()) && (
        <div className="upload-spinner-wrapper">
            <Spinner className="spinner" animation="grow" />
            </div>
        )}
      </div>
    )
  };

  return (
    <div
      className="upload-wrapper"
      style={{ height: `${Math.round(outletBlockWidth / 3) + 21}px` }}
      mandatory={mandatory.toString()}
    >
      {renderUploadButton()}
      {renderExample()}
      {renderPreview()}
      {renderLowerButtons()}
    </div>
  );
};

export default UploadFile;
