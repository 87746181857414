import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePasswordTokenStatusAC,
  triggerRedirectAC,
} from "../../../actions-thunks/actions";

import { authThunks } from "../../../actions-thunks/auth-thunks";
import {
  TO_HOME_PAGE,
  TO_SIGN_IN,
} from "../../../reducers/types";
import Button from "../../utilities/element/Button";
import EyeIcon from "../../utilities/EyeIcon";
import { utilityFns } from "../../utilities/utility-functions";

import "./activation-page.scss";

const ActivationPage = ({ token, resetPassword, setTokenExpiredWindow }) => {
  const navigate = useNavigate();
  const { authenticated, changePasswordTokenStatus } = useSelector(
    (state) => state.auth
  );
  const [activateSubmit, setActivateSubmit] = useState(true);
  const [localState, setLocalState] = useState({
    password: "",
    similar: false,
    confirmPassword: "",
    passwordErrorMessage: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const {
    password,
    similar,
    confirmPassword,
    passwordErrorMessage,
  } = localState;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!token) {
      dispatch(authThunks.checkActivateTokenValidityTC(token));
    } else {
      dispatch(triggerRedirectAC({ direction: TO_SIGN_IN, status: true }));
    }
    return () => {
      dispatch(changePasswordTokenStatusAC(null));
    };
  }, [dispatch, token]);

  useEffect(() => {
    if (!!changePasswordTokenStatus && changePasswordTokenStatus.status) {
      activateTimer(changePasswordTokenStatus.minutesToExpiration);
    }
    if (changePasswordTokenStatus !== null && !changePasswordTokenStatus?.status) {
      setActivateSubmit(false);
      resetPassword ? setTokenExpiredWindow({ from: "pass" }) : setTokenExpiredWindow({ from: "activate" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordTokenStatus]);

  useEffect(() => {
    if (authenticated) {
      navigate(TO_HOME_PAGE);
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (
      password === confirmPassword &&
      confirmPassword) {
      setLocalState((l) => ({ ...l, similar: true, passwordErrorMessage: "" }));
    } else {
      setLocalState((l) => ({
        ...l,
        similar: false,
        passwordErrorMessage: "Passwords do not match!",
      }));
    }
  }, [password, confirmPassword]);

  const activateTimer = (minutes) => {
    setTimeout(() => {
      dispatch(
        changePasswordTokenStatusAC({
          status: false,
          minutesToExpiration: null,
        })
      );
      setActivateSubmit(false);
    }, 60000 * minutes);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ ...localState, [name]: value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (password.length > 40) {
      setLocalState((l) => ({
        ...l,
        passwordErrorMessage:
          "Password length exceeds maximum allowed 40 characters.",
      }));
      return;
    }
    if (!utilityFns.validatePassword(password)) {
      setLocalState((l) => ({
        ...l,
        passwordErrorMessage:
          "Password must contain at least 8 characters, a capital letter, a symbol and a number.",
      }));
      return;
    }
    if (resetPassword) {
      dispatch(authThunks.changePasswordTC({ password }, token));
    } else {
      dispatch(
        authThunks.activateTC(token, { password })
      );
    }
    setActivateSubmit(false);
  };

  return (
    <div className="form-block-activate">
      <Form className="form-wrapper">
        <h4>{resetPassword ? "Reset Password" : "Confirm Registration"}</h4>
        <Form.Group className="form-group-block mb-3">
          <Form.Label className="form-label center-label ">Password</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            disabled={!activateSubmit}
            value={password}
            onChange={handleChange}
            name="password"
            placeholder="Password"
          />
          <span
            className="see-pasword"
            onClick={() => setShowPassword(!showPassword)}
          >
            <EyeIcon show={showPassword} />
          </span>
        </Form.Group>
        <Form.Group className="form-group-block mb-3">
          <Form.Label className="form-label center-label ">
            Confirm Password
          </Form.Label>
          <Form.Control
            disabled={!activateSubmit}
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={handleChange}
            name="confirmPassword"
            placeholder="Confirm password"
          />
          <span
            className="see-pasword"
            onClick={() => setShowPassword(!showPassword)}
          >
            <EyeIcon show={showPassword} />
          </span>
        </Form.Group>
        <Button disabled={!activateSubmit || !similar} onClick={submitHandler}>
          {resetPassword ? "Change" : "Register"}
        </Button>
        <div className="not-match-pass-block">
          {confirmPassword ? (
            <div className="error-message">{passwordErrorMessage}</div>
          ) : null}
        </div>
        {changePasswordTokenStatus?.minutesToExpiration && <span className="warn-message">
          <div>This activation form will expire in {" "}
            {changePasswordTokenStatus?.minutesToExpiration} minutes.</div>
        </span>}
      </Form>
    </div>
  );
};

export default React.memo(ActivationPage);
