
const setPB = (r, PB, limits) => {
    const {performanceBond, initialBuyingPower, maximumDailyLoss, overnightBuyingPower} = limits;
    const i = [(PB - performanceBond.min) / performanceBond.step];
    r.IBP = initialBuyingPower.min + (i * initialBuyingPower.step);
    r.PB = performanceBond.min + (i * performanceBond.step);
    r.MDL = maximumDailyLoss.min + (i * maximumDailyLoss.step);
    if (r.overnight) r.OBP = overnightBuyingPower.min + (i * overnightBuyingPower.step)
}
const setIBP = (r, IBP, limits) => {
    const {performanceBond, initialBuyingPower, maximumDailyLoss, overnightBuyingPower} = limits;
    const i = (IBP - initialBuyingPower.min) / initialBuyingPower.step;
    r.IBP = initialBuyingPower.min + (i * initialBuyingPower.step);
    r.PB = performanceBond.min + (i * performanceBond.step);
    r.MDL = maximumDailyLoss.min + (i * maximumDailyLoss.step);
    if (r.overnight) r.OBP = overnightBuyingPower.min + (i * overnightBuyingPower.step)
}
const setMDL = (r, MDL, limits) => {
    const {performanceBond, initialBuyingPower, maximumDailyLoss, overnightBuyingPower} = limits;
    const i = (MDL - maximumDailyLoss.min) / maximumDailyLoss.step;
    r.IBP = initialBuyingPower.min + (i * initialBuyingPower.step);
    r.PB = performanceBond.min + (i * performanceBond.step);
    r.MDL = maximumDailyLoss.min + (i * maximumDailyLoss.step);
    if (r.overnight) r.OBP = overnightBuyingPower.min + (i * overnightBuyingPower.step)
}
const setOBP = (r, OBP, limits) => {
    const {performanceBond, initialBuyingPower, maximumDailyLoss, overnightBuyingPower} = limits;
    const i = (OBP - overnightBuyingPower.min) / overnightBuyingPower.step;
    r.IBP = initialBuyingPower.min + (i * initialBuyingPower.step);
    r.PB = performanceBond.min + (i * performanceBond.step);
    r.MDL = maximumDailyLoss.min + (i * maximumDailyLoss.step);
    r.OBP = overnightBuyingPower.min + (i * overnightBuyingPower.step)
}
const setOvernight = (r, PB, limits) => {
    const {performanceBond, overnightBuyingPower} = limits;
    const i = [(PB - performanceBond.min) / performanceBond.step];
    if (r.overnight) {
        r.OBP = overnightBuyingPower.min + (i * overnightBuyingPower.step)
    } else {
        r.OBP = 0
    }
}
const RateCalculator = { setIBP, setMDL, setOBP, setPB, setOvernight };

export default RateCalculator;